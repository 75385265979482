import React from 'react';

import clsx from 'clsx';

type Props = React.ComponentProps<'button'> & {
    size?: 'default' | 'small';
    icon?: string;
};

const Button = React.forwardRef<HTMLButtonElement, Props>(
    ({children, className, size, icon, ...props}, ref) => {
        return (
            <button
                ref={ref}
                className={clsx(
                    `before:absolute before:content-['']`,
                    size === 'small'
                        ? 'btn-small before:w-[30px] before:h-10'
                        : 'btn before:w-11 before:h-14',
                    `button flex items-center relative hover:brightness-125 disabled:brightness-75`,
                    `after:absolute after:right-0 after:content-['']`,
                    size === 'small'
                        ? 'after:w-[30px] after:h-10'
                        : 'after:w-11 after:h-14',
                    className,
                )}
                {...props}
            >
                <div
                    className={clsx(
                        'bg-repeat-x text-antique px-2 gap-3 bg-btn-center flex min-w-0 flex-grow justify-center',
                        size === 'small'
                            ? 'bg-btn-small-center h-10 leading-[40px] mx-[30px]'
                            : 'bg-btn-center h-14 leading-[56px] mx-11',
                    )}
                >
                    {icon && <img src={icon} width={24} />}
                    <span className="truncate">{children}</span>
                </div>
            </button>
        );
    },
);

Button.defaultProps = {
    size: 'default',
};

Button.displayName = 'Button';
export default Button;
