/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import './src/styles/global.css'
import CookieConsent from './src/components/CookieConsent';

export const wrapRootElement = ({ element }) => {
    return (
      <div>
        {element}
        <CookiesProvider>
          <CookieConsent />
        </CookiesProvider>
      </div>
    );
  };